<template>
  <div class="flex items-center h-10 bg-main-100 px-2 bg-[#1C1316] text-white">
    <div
      class="relative w-[6.8rem] h-8 text-[13px] flex items-center justify-center gap-2 bg-blue-dark rounded-full shadow">
      <img class="h-5 w-5" src="@/assets/icons/speaker.svg" alt="" srcset="" />
      新葡京公告：
    </div>
    <div
      class="flex-1 h-6 flex items-center text-xs relative overflow-x-hidden text-white font-light mx-2 bg-main-300 bg-opacity-25">
      <div :style="{ 'animation-duration': `${time}s` }" style="left: 100%;white-space: nowrap;" class="marquee absolute">
        {{ mainInfo?.site_marquee }}
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, ref } from '@vue/runtime-core'
import { useStore } from 'vuex'
const store = useStore()
const mainInfo = computed(() => store.state.mainInfo)
const time = computed(() => {
  const speedPerLetter = mainInfo.value?.site_marquee?.length / 2.5
  return speedPerLetter > 5 ? speedPerLetter : 5
})
</script>

<style lang="scss" scoped>
@keyframes marquee {
  10% {
    left: 100%;
    transform: translateX(0);
  }

  100% {
    left: 0;
    transform: translateX(-100%);
  }
}

.marquee {
  animation-name: marquee;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  transition: transform 0.016s;
  transition-timing-function: linear;
}
</style>
