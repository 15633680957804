<template>
    <van-popup v-model:show="show" round :overlay-style="{ padding: '1rem' }" @close="emits('close')"
        :style="{ padding: '1rem 0', backgroundColor: '#2B2633', width: '100%' }">
        <div class="w-full h-full overflow-y-scroll">
            <div class="flex items-center justify-between px-3 pb-4 border-b border-white border-opacity-10">
                <div class="flex items-center gap-3">
                    <div class="w-[2px] h-5 line" />
                    <p class="text-lg">资产转移</p>
                </div>
                <van-icon @click="close" name="cross" size="24px" color="#909093" />
            </div>
            <div class="border-b border-white border-opacity-10 py-8 px-4 text-[15px]">
                <span class="text-[#909093]">电子钱包： </span>$88,888.00
            </div>
            <div class="px-3 pt-4 pb-3 flex items-center gap-3 text-[14px]">
                <p>操作：</p>
                <div class="flex items-center gap-3 text-xs">
                    <div class="bg-[#24B6E4] w-24 text-center leading-6 rounded-full" @click="swapType = 1"
                        :class="{ 'active': swapType === 1 }">
                        转点
                    </div>
                    <div class="bg-[#24B6E4] w-24 text-center leading-6 rounded-full" @click="swapType = 2"
                        :class="{ 'active': swapType === 2 }">
                        刷新余额
                    </div>
                </div>
            </div>
            <div class="px-3 py-4 text-[14px]">
                <div @click="swapFormShow = true" class="bg-[#1C1316] rounded-lg flex items-center gap-5 mb-4">
                    <img src="@/assets/images/car-withdraw.png" alt="image">
                    <div class="flex flex-col gap-3">
                        <p class="text-[#909093] border-b border-[#909093] w-[fit-content] text-base">极速赛船</p>
                        <p class="text-lg">$12,000.00</p>
                    </div>
                </div>
                <div class="bg-[#1C1316] rounded-lg flex items-center gap-5">
                    <img src="@/assets/images/usdt-whitdraw.png" alt="image">
                    <div class="flex flex-col gap-3">
                        <p class="text-[#909093] border-b border-[#909093] w-[fit-content] text-base">USDT合约</p>
                        <p class="text-lg">$88.00</p>
                    </div>
                </div>
            </div>
        </div>
    </van-popup>
    <van-popup v-model:show="swapFormShow" round :overlay-style="{ padding: '1rem' }"
        :style="{ width: '100%', padding: '1rem 0' }" @close="swapFormShow = false">
        <div class="flex items-center justify-between px-3 pb-4 border-b border-[#909093] border-opacity-30 text-black">
            <p class="text-lg font-medium">资产转移</p>
            <van-icon @click="swapFormShow = false" name="cross" size="24px" color="#909093" />
        </div>
        <div class="py-6 px-3 text-sm">
            <p class="text-[#909093] mb-2">转出</p>
            <Select mode="light" placeholder="请选择游戏" :modelVal="swapIn" :options="selectOptions"
                @update:value="swapIn = $event" />
            <p class="text-[#909093] my-2">转入</p>
            <Select mode="light" placeholder="请选择游戏" :modelVal="swapOut" :options="selectOptions"
                @update:value="swapOut = $event" />
            <p class="text-[#909093] my-2">输入金额</p>
            <input
                class="bg-[#D9D9D9] w-full flex px-3 items-center justify-between py-3 text-sm mb-4 rounded-lg text-black border border-transparent hover:border-[#24B6E4]"
                type="number">
        </div>
        <div class="mx-3 rounded-full bg-[#24B6E4] text-center py-3 text-sm">
            送出
        </div>
        <p class="text-sm text-[#909093] mt-2 text-center">取消</p>
    </van-popup>
</template>
<script setup>
import { ref, watch } from 'vue'
import Select from '../components/Select.vue'
const props = defineProps({
    isShow: {
        type: Boolean,
        default: false
    }
})
const emits = defineEmits(['close'])
const show = ref(false)
const swapFormShow = ref(false)
const swapType = ref(1)
const selectOptions = ref(['电子钱包', '极速赛船', 'USDT合约'])
const swapIn = ref(null)
const swapOut = ref(null)
const close = () => {
    show.value = false
    emits('close')
}
watch(
    () => props.isShow,
    (newVal) => {
        show.value = props.isShow
    }
)
</script>
<style lang="scss" scoped>
.line {
    background: linear-gradient(180deg, #FF2A6D, #FF2AAF);
}

.active {
    box-shadow: 0 0 15px rgba(37, 186, 233, 0.8);
}
</style>