<template>
    <van-popup v-model:show="show" round :overlay-style="{ padding: '1rem' }" @close="emits('close')"
        :style="{ padding: '1rem 0', backgroundColor: '#2B2633', width: '100%', height: '95%' }">
        <div class="w-full h-full overflow-y-scroll">
            <div class="flex items-center justify-between px-3 pb-4 border-b border-white border-opacity-10">
                <div class="flex items-center gap-3">
                    <div class="w-[2px] h-5 line" />
                    <p class="text-lg">交易记录</p>
                </div>
                <van-icon @click="close" name="cross" size="24px" color="#909093" />
            </div>
            <div class="border-b border-white border-opacity-10 py-8 px-4 text-[15px]">
                <span class="text-[#909093]">电子钱包： </span>${{ +userInfo.last_point || '0.00' }}
            </div>
            <div class="relative pb-3 pt-6 flex items-center text-[14px] border-b border-white border-opacity-10">
                <p v-for="(item, index) of tabs" :key="item" class="flex-1 text-center"
                    :class="activeTab === index ? 'text-white' : 'text-[#909093]'" @click="activeTab = index">
                    {{ item }}
                </p>
                <div class="w-1/2 absolute bottom-0 transform translate-y-1/2 transition-all duration-200"
                    :style="`left:${activeTab * 50}%`">
                    <div class="w-10 h-[2px] bg-[#24B6E4] mx-auto" />
                </div>
            </div>
            <div v-for="item of list" :key="item.id" class="bg-[#1C1316] rounded-lg p-4 m-4 text-[13px] leading-6">
                <p>{{ formatDate(item.created, 'yyyy-MM-dd hh:mm:ss') }}</p>
                <p v-show="activeTab === 1">帐户：{{ `(${item.bank_info?.bank_name})
                                    ${hidesCard(item.bank_info?.bank_card_no)}`
                }}</p>
                <p>转{{ activeTab ? '出' : '入' }}：电子钱包</p>
                <p>状态：{{ stateMap[item.state] }}</p>
                <p v-if="+item.state !== 0 && activeTab">审核时间：{{ formatDate(item.done_at, 'yyyy-MM-dd hh:mm:ss') }}</p>
                <p>金额：{{ +item.point }}</p>
            </div>
            <div v-if="!list.length" class="w-full py-20 flex flex-col items-center justify-center gap-3">
                <img src="@/assets/icons/no-data.svg" />
                <p class="text-sm text-[#909093]">暂无资料</p>
            </div>
        </div>
    </van-popup>
</template>
<script setup>
import { ref, watch, computed } from 'vue'
import { useStore } from 'vuex'
import { get_withdraw_list, get_recharge_list } from '@/api/cash'
import { formatDate } from '@/utils/date'
const store = useStore()
const props = defineProps({
    isShow: {
        type: Boolean,
        default: false
    }
})
const emits = defineEmits(['close'])
const tabs = ref(['储值记录', '提领记录'])
const stateMap = ref(['未审核', '已成功 到账', '已驳回'])
const chargeList = ref([])
const withdrawList = ref([])
const activeTab = ref(0)
const show = ref(false)
const userInfo = computed(() => store.state.userInfo)
const list = computed(() => activeTab.value === 0 ? chargeList.value : withdrawList.value)

const close = () => {
    show.value = false
    emits('close')
}
const hidesCard = (str) => {
    if (!str) return ''
    else
        return str.substr(0, 4) + '******' + str.substr(-4)
}
watch(
    () => props.isShow,
    async (newVal) => {
        show.value = props.isShow
        if (newVal) {
            const withdrawRes = await get_withdraw_list()
            if (withdrawRes) withdrawList.value = withdrawRes
            const chargeRes = await get_recharge_list()
            if (chargeRes) chargeList.value = chargeRes
        }
    }
)
</script>
<style lang="scss" scoped>
.line {
    background: linear-gradient(180deg, #FF2A6D, #FF2AAF);
}

.active {
    box-shadow: 0 0 15px rgba(37, 186, 233, 0.8);
}
</style>