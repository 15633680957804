<template>
  <div
    class="w-full h-screen fixed inset-0 z-20 bg-[#111112] main transform transition-transform duration-300"
    :class="isShow ? 'translate-x-0' : 'translate-x-full'"
  >
    <div class="px-4 py-3 flex items-center justify-between bg-[#202126]">
      <router-link to="/message">
        <img v-if="+isReadCount" class="w-5" src="@/assets/icons/message.svg" />
        <img v-else class="w-5" src="@/assets/icons/unnewmsg.svg" />
      </router-link>
      <img src="@/assets/logo.svg" alt="logo" />
      <van-icon
        @click="emits('close')"
        name="cross"
        color="#909093"
        size="25px"
      />
    </div>
    <div class="px-8 py-8">
      <div class="flex items-center gap-3 text-[#24B6E4] text-lg">
        <img class="w-6" src="@/assets/icons/user.svg" alt="icon" />
        {{ userInfo.username }}
        <!-- <img src="@/assets/icons/eye-open.svg" alt="icon"> -->
      </div>
      <div class="ml-1 mt-2 text-xl flex items-center gap-3">
        <img src="@/assets/icons/coin.svg" alt="logo" />
        {{ +userInfo.last_point || '0.00' }}
      </div>
    </div>
    <div class="flex items-center py-2 px-4 mb-4">
      <div
        @click="buyPShow = true"
        class="btn-blue flex justify-between px-4 py-6 rounded-lg flex-1 mr-2"
      >
        <div class="flex items-center">
          <img class="w-6 mr-2" src="@/assets/icons/buy-coin.svg" />
          <p class="text-[15px]">充值现金</p>
        </div>
        <img class="w-4 transform rotate-180" src="@/assets/icons/back.svg" />
      </div>
      <div
        @click="sellPShow = true"
        class="btn-blue flex justify-between px-4 py-6 rounded-lg flex-1 ml-2"
      >
        <div class="flex items-center">
          <img class="w-6 mr-2" src="@/assets/icons/sell-coin.svg" />
          <p class="text-[15px]">提领现金</p>
        </div>
        <img class="w-4 transform rotate-180" src="@/assets/icons/back.svg" />
      </div>
    </div>
    <div
      v-for="link of links"
      :key="link.title"
      class="flex items-center justify-between py-4 bg-[#1C1316] px-4"
      @click="link.action"
    >
      <div class="flex items-center gap-3 text-[15px]">
        <img :src="link.icon" />
        <p>{{ link.title }}</p>
      </div>
      <van-icon name="arrow" />
    </div>
  </div>
  <UserCenter :isShow="userCenterShow" @close="userCenterShow = false" />
  <BuyP :isShow="buyPShow" @close="buyPShow = false" />
  <SellP :isShow="sellPShow" @close="sellPShow = false" />
  <Swap :isShow="swapShow" @close="swapShow = false" />
  <CashFlow :isShow="cashFlowShow" @close="cashFlowShow = false" />
</template>
<script setup>
import { computed, ref } from '@vue/runtime-core';
import { useStore } from 'vuex';
import { set_sign_out } from '@/api/main';
import UserCenter from '../components/UserCenter.vue';
import BuyP from '../components/BuyP.vue';
import SellP from '../components/SellP.vue';
import Swap from '../components/Swap.vue';
import CashFlow from '../components/CashFlow.vue';
const store = useStore();
const userInfo = computed(() => store.state.userInfo);
const props = defineProps({
  isShow: {
    type: Boolean,
    default: true,
  },
});
const emits = defineEmits(['close']);
const userCenterShow = ref(false);
const buyPShow = ref(false);
const sellPShow = ref(false);
const swapShow = ref(false);
const cashFlowShow = ref(false);
const links = ref([
  {
    title: '会员中心',
    icon: require('@/assets/icons/my/user.svg'),
    action: () => {
      userCenterShow.value = true;
    },
  },
  // {
  //     title: '资产转移',
  //     icon: require('@/assets/icons/my/swap.svg'),
  //     action: () => {
  //         swapShow.value = true
  //     },
  // },
  {
    title: '交易记录',
    icon: require('@/assets/icons/my/record.svg'),
    action: () => {
      cashFlowShow.value = true;
    },
  },
  // {
  //     title: '客服中心',
  //     icon: require('@/assets/icons/my/service.svg'),
  //     action: async () => {
  //     },
  // },
  {
    title: '帐号登出',
    icon: require('@/assets/icons/my/out.svg'),
    action: async () => {
      await set_sign_out();
    },
  },
]);
const isReadCount = computed(() => {
  return store.state.unread_count?.sys_unread;
});
</script>
<style scoped>
.main {
  background-image: url('~@/assets/images/my.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 0 3rem;
}

.btn-blue {
  background: linear-gradient(
    90deg,
    rgba(29, 163, 205, 0.3),
    rgba(37, 186, 233, 0.3)
  );
}
</style>
