<template>
    <van-popup v-model:show="show" round :overlay-style="{ padding: '1rem' }" @close="emits('close')"
        :style="{ padding: '1.5rem 0', backgroundColor: '#2B2633', width: '100%', height: '95%' }">
        <div class="w-full h-full overflow-y-scroll">
            <div class="flex items-center justify-between px-3 pb-4 border-b border-white border-opacity-10">
                <div class="flex items-center gap-3">
                    <div class="w-[2px] h-5 line" />
                    <p class="text-lg">会员中心</p>
                </div>
                <van-icon @click="close" name="cross" size="24px" color="#909093" />
            </div>
            <div class="px-3 py-4 text-[14px]">
                <p class="text-[#909093] mb-2">登入帐号</p>
                <div class="bg-[#1C1316] flex px-4 items-center justify-between py-3 text-sm mb-4 rounded-lg">
                    <p>{{ userInfo.username }}</p>
                </div>
                <p class="text-[#909093] mb-2">会员姓名</p>
                <div class="bg-[#1C1316] flex px-4 items-center justify-between py-3 text-sm mb-4 rounded-lg">
                    <p>{{ userInfo.realname }}</p>
                </div>
                <p class="text-[#909093] mb-2">手机号码</p>
                <div class="bg-[#1C1316] flex px-4 items-center justify-between py-2 text-sm mb-4 rounded-lg">
                    <p>{{ hideNumber(userInfo.mobile) }}</p>
                    <div class="bg-[#24B6E4] text-black text-xs rounded-lg px-3 py-2">未验证</div>
                </div>
                <p class="text-[#909093] mb-2">修改密码</p>
                <div class="bg-[#1C1316] mb-4 rounded-lg flex items-center px-4">
                    <input ref="passwordIpt" v-model="password"
                        class="w-full bg-transparent flex items-center justify-between py-3 text-sm placeholder-[#909093]"
                        :type="passwordIptType" placeholder="输入新密码">
                    <img v-show="passwordIptType === 'password'" @click="passwordIptType = 'text'" class="w-5"
                        src="@/assets/icons/sign/eyes-open.svg" />
                    <img v-show="passwordIptType === 'text'" @click="passwordIptType = 'password'" class="w-5"
                        src="@/assets/icons/sign/eyes-close.svg" />
                </div>
                <p class="text-[#909093] mb-2">确认新密码</p>
                <div class="bg-[#1C1316] mb-4 rounded-lg flex items-center px-4">
                    <input ref="checkPasswordIpt" v-model="check_password"
                        class="w-full bg-transparent flex items-center justify-between py-3 text-sm placeholder-[#909093]"
                        :type="checkPasswordIptType" placeholder="再次输入新密码">
                    <img v-show="checkPasswordIptType === 'password'" @click="checkPasswordIptType = 'text'" class="w-5"
                        src="@/assets/icons/sign/eyes-open.svg" />
                    <img v-show="checkPasswordIptType === 'text'" @click="checkPasswordIptType = 'password'" class="w-5"
                        src="@/assets/icons/sign/eyes-close.svg" />
                </div>
            </div>
            <div @click="submit" class="mx-3 rounded-full bg-[#24B6E4] text-center py-3 text-sm">
                存储
            </div>
            <p class="text-sm text-[#909093] mt-2 text-center" @click="close">取消</p>
            <div class="mt-6 border-t border-[#909093] border-opacity-30 mx-3 pt-4 text-sm">
                <p>注意事项：</p>
                <p class="text-[#909093]">如遇不可抗拒因素，需要变更资料，请洽询客服人员！</p>
            </div>
        </div>
    </van-popup>
</template>
<script setup>
import { ref, computed, watch } from 'vue'
import { useStore } from 'vuex'
import { showFailToast } from 'vant';
import { set_password_change } from '@/api/main'
const props = defineProps({
    isShow: {
        type: Boolean,
        default: false
    }
})
const emits = defineEmits(['close'])
const store = useStore()
const show = ref(false)
const password = ref('')
const check_password = ref('')
const passwordIptType = ref('password')
const checkPasswordIptType = ref('password')
const userInfo = computed(() => store.state.userInfo)
const submit = async () => {
    if (password.value !== check_password.value) {
        showFailToast('密码与确认密码不同')
        return
    }
    const res = await set_password_change({
        password: password.value
    })
    if (res) {
        password.value = ''
        check_password.value = ''
    }
}
const close = () => {
    show.value = false
    emits('close')
}
const hideNumber = (str) => {
    if (!str) return ''
    else return str.substr(0, 3) + '****' + str.substr(-4)
}
watch(
    () => props.isShow,
    (newVal) => {
        show.value = props.isShow
    }
)
</script>
<style lang="scss" scoped>
.line {
    background: linear-gradient(180deg, #FF2A6D, #FF2AAF);
}
</style>