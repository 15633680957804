import axios from '@/utils/axios';
import store from '@/store/index.js';
import { showSuccessToast, showFailToast } from 'vant';
import { get_user_info } from './main';

//===================提现申请===================//
export async function get_withdraw_apply(payload) {
  store.commit('set_is_loading', true);
  const data = await axios.post('/api/withdraw/apply', payload);
  if (!data.error) {
    showSuccessToast('申请成功');
    get_user_info();
    store.commit('set_is_loading', false);
    return true;
  } else {
    store.commit('set_is_loading', false);
    showFailToast(data.msg);
    return false;
  }
}
//===================提现记录===================//
export async function get_withdraw_list(payload) {
  store.commit('set_is_loading', true);
  const data = await axios.post('/api/withdraw/list', payload);
  if (!data.error) {
    store.commit('set_is_loading', false);
    return data.ret.data;
  } else {
    store.commit('set_is_loading', false);
    showFailToast(data.msg);
    return false;
  }
}

//===================储值记录 ===================//
export async function get_recharge_list(payload) {
  store.commit('set_is_loading', true);
  const data = await axios.post('/api/charge/list', payload);
  if (!data.error) {
    store.commit('set_is_loading', false);
    return data.ret.data;
  } else {
    store.commit('set_is_loading', false);
    showFailToast(data.msg);
    return false;
  }
}
