<template>
  <van-popup
    v-model:show="show"
    round
    :overlay-style="{ padding: '1rem' }"
    @close="emits('close')"
    :style="{
      padding: '1rem 0',
      backgroundColor: '#2B2633',
      width: '100%',
      height: '95%',
    }"
  >
    <div class="w-full h-full overflow-y-scroll">
      <div
        class="flex items-center justify-between px-3 pb-4 border-b border-white border-opacity-10"
      >
        <div class="flex items-center gap-3">
          <div class="w-[2px] h-5 line" />
          <p class="text-lg">提领现金</p>
        </div>
        <van-icon @click="close" name="cross" size="24px" color="#909093" />
      </div>
      <div
        class="border-b border-white border-opacity-10 py-8 px-4 text-[15px]"
      >
        <span class="text-[#909093]">电子钱包： </span>${{
          +userInfo.last_point || '0.00'
        }}
      </div>
      <div class="px-3 py-4 text-[14px]">
        <template v-if="!userInfo.bank_info?.bank_card_no">
          <p class="text-[#909093] mb-2">国家</p>
          <Select
            mode="dark"
            :modelVal="country"
            :options="countriesList"
            label="chinese_name"
            placeholder="请选择国家"
            @update:value="country = $event"
            class="mb-4"
          />
        </template>
        <p class="text-[#909093] mb-2">银行名称</p>
        <div
          v-if="userInfo.bank_info?.bank_card_no"
          class="bg-[#1C1316] text-[#909093] flex px-3 items-center justify-between py-3 text-sm mb-4 rounded-lg border border-transparent hover:border-[#24B6E4]"
        >
          {{ userInfo.bank_info?.bank_name }}
        </div>
        <Select
          v-else
          mode="dark"
          :modelVal="bankName"
          :options="countryBankList"
          label="bank_name"
          placeholder="请选择银行"
          @update:value="
            (bankName = $event), (bankForm[0].val = countryBankList[$event].id)
          "
          class="mb-4"
        />

        <p class="text-[#909093] mb-2">帐号</p>
        <div
          v-if="userInfo.bank_info?.bank_card_no"
          class="bg-[#1C1316] text-[#909093] flex px-3 items-center justify-between py-3 text-sm mb-4 rounded-lg border border-transparent hover:border-[#24B6E4]"
        >
          {{ hidesCard(userInfo.bank_info?.bank_card_no) }}
        </div>
        <input
          v-else
          class="bg-[#1C1316] flex items-center placeholder-[#909093] text-sm w-full px-4 py-3 my-4 rounded-lg border border-transparent hover:border-[#24B6E4]"
          type="text"
          :placeholder="bankForm[1].placeholder"
          v-model="bankForm[1].val"
          maxlength="19"
          oninput="value=value.replace(/[^\d]/g,'')"
        />
        <p class="text-[#909093] mb-2">户名</p>
        <div
          v-if="userInfo.bank_info?.realname"
          class="bg-[#1C1316] text-[#909093] flex px-3 items-center justify-between py-3 text-sm mb-4 rounded-lg border border-transparent hover:border-[#24B6E4]"
        >
          {{ hidesString(userInfo.bank_info?.realname) }}
        </div>
        <input
          v-else
          class="bg-[#1C1316] flex items-center placeholder-[#909093] text-sm w-full px-4 py-3 my-4 rounded-lg border border-transparent hover:border-[#24B6E4]"
          type="text"
          :placeholder="bankForm[2].placeholder"
          v-model="bankForm[2].val"
        />
        <p class="text-[#909093] mb-2">提领方式</p>
        <Select
          mode="dark"
          placeholder="请选择提领方式"
          :modelVal="withdrawType"
          :options="selectOptions"
          @update:value="withdrawType = $event"
        />
        <p class="text-[#909093] mb-2 mt-4">提领总额</p>
        <input
          v-model="point"
          class="bg-[#1C1316] w-full flex px-3 items-center justify-between py-3 text-sm mb-4 rounded-lg placeholder-[#909093] border border-transparent hover:border-[#24B6E4]"
          type="number"
        />
      </div>
      <div
        class="mx-3 rounded-full bg-[#24B6E4] text-center py-3 text-sm"
        @click="submit"
      >
        送出
      </div>
      <p class="text-sm text-[#909093] mt-2 text-center" @click="close">取消</p>
      <div
        v-if="mainInfo.notice"
        class="mt-6 border-t border-[#909093] border-opacity-30 mx-3 pt-4 text-sm"
      >
        <p>注意事项：</p>
        <div
          class="list-decimal list-inside text-[#909093]"
          v-html="mainInfo.notice"
        ></div>
      </div>
    </div>
  </van-popup>
</template>
<script setup>
import { ref, computed, watch } from 'vue';
import { useStore } from 'vuex';
import Select from '../components/Select.vue';
import { get_withdraw_apply } from '@/api/cash';
import { set_bank_card } from '@/api/main';
import BankSelect from '../components/BankSelect.vue';

const props = defineProps({
  isShow: {
    type: Boolean,
    default: false,
  },
});
const emits = defineEmits(['close']);
const point = ref(null);
const selectOptions = ref(['电子钱包']);
const withdrawType = ref(0);
const store = useStore();
const show = ref(false);
const userInfo = computed(() => store.state.userInfo);
const mainInfo = computed(() => store.state.mainInfo);
const countriesList = computed(() => store.state.phoneCountries);
const bankList = computed(() => store.state.bankList);
const country = ref(null);
const currentCountryInfo = computed(
  () => countriesList.value[country.value] || {}
);
const countryBankList = computed(() => {
  return (
    bankList.value.filter((e) => e.country === currentCountryInfo.value.iso2) ||
    []
  );
});
const bankName = ref(null);
const bankForm = ref([
  {
    name: 'bank_id',
    val: '',
    inputType: 'text',
    eyesShow: false,
    placeholder: '请输入您的银行名称',
    error: false,
    errorMsg: '银行名称不能为空白',
    icon: require('@/assets/icons/sign/card.svg'),
  },
  {
    name: 'bank_card_no',
    val: '',
    inputType: 'number',
    eyesShow: false,
    placeholder: '请输入您的银行卡号',
    error: false,
    errorMsg: '银行卡号不能为空白',
    icon: require('@/assets/icons/sign/card.svg'),
  },
  {
    name: 'bank_realname',
    val: '',
    inputType: 'text',
    eyesShow: false,
    placeholder: '请输入您的户名',
    error: false,
    errorMsg: '户名不能为空白',
    icon: require('@/assets/icons/sign/account.svg'),
  },
]);
const close = () => {
  show.value = false;
  emits('close');
};
const submit = async () => {
  if (!userInfo.value.bank_info?.bank_card_no) {
    const res = await set_bank_card({
      bank_id: bankForm.value[0].val,
      bank_card_no: bankForm.value[1].val,
      realname: bankForm.value[2].val,
    });
    if (!res) return;
  }
  await get_withdraw_apply({
    point: point.value,
  });
  point.value = null;
};
const hidesString = (str) => {
  let defaultStr = '';
  if (!str) return '';
  else {
    for (let i = 0; i < str.length - 2; i++) {
      defaultStr += '*';
    }
    return str.substr(0, 1) + defaultStr + str.substr(-1);
  }
};
const hidesCard = (str) => {
  if (!str) return '';
  else return str.substr(0, 4) + '******' + str.substr(-4);
};
watch(
  () => props.isShow,
  (newVal) => {
    show.value = props.isShow;
  }
);
watch(
  () => country.value,
  (newVal) => {
    bankName.value = null;
  }
);
</script>
<style lang="scss" scoped>
.line {
  background: linear-gradient(180deg, #ff2a6d, #ff2aaf);
}
</style>
