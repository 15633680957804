<template>
  <div class="home">
    <div class="h-[3.5rem] bg-[#202126] flex justify-between items-center px-4">
      <img
        @click="authMenuLogin"
        class="transform rotate-180"
        src="@/assets/icons/menu.svg"
        alt="menu"
      />
      <img src="@/assets/logo.svg" alt="logo" />
      <router-link to="/message">
        <img v-if="+isReadCount" class="w-5" src="@/assets/icons/message.svg" />
        <img v-else class="w-5" src="@/assets/icons/unnewmsg.svg" />
      </router-link>
    </div>
    <van-swipe
      class="my-swipe"
      :autoplay="7000"
      indicator-color="white"
      height="142"
    >
      <van-swipe-item
        v-for="item of mainInfo.banners?.Main_Banner"
        :key="item.id"
      >
        <img class="w-full h-full" :src="item.image_url" alt="banner" />
      </van-swipe-item>
    </van-swipe>
    <Marquee />
    <div class="flex items-center gap-3 px-4 text-sm mt-3">
      <p class="text-base">在线人数：</p>
      <Counter />
    </div>

    <div class="">
      <div class="flex items-center gap-3 px-3 py-3">
        <div class="w-[2px] h-6 line" />
        <p class="text-xl">多元投资</p>
      </div>
    </div>
    <router-link to="/market" class="block relative px-3">
      <img class="w-full" src="@/assets/images/games/usdt.png" alt="usdt" />
      <div
        class="absolute top-1/2 right-10 transform -translate-y-1/2 text-right mt-5"
      >
        <p class="text-[19px] mb-2">USDT合约</p>
        <div
          class="btn-pink text-xs rounded-full w-[fit-content] px-3 py-[6px] float-right"
        >
          立即开始
        </div>
      </div>
    </router-link>
    <div class="flex items-center gap-3 px-3 py-3">
      <div class="w-[2px] h-6 line" />
      <p class="text-xl mt-2">经典投资</p>
    </div>
    <router-link to="/game-list" class="block relative px-3 mb-6">
      <img class="w-full" src="@/assets/images/car-enter.png" alt="usdt" />
      <div
        class="absolute top-1/2 right-10 transform -translate-y-1/2 text-right mt-5"
      >
        <p class="text-[19px] mb-2 text-shadow">急速系列</p>
        <div
          class="btn-pink text-xs rounded-full w-[fit-content] px-3 py-[6px] float-right"
        >
          立即开始
        </div>
      </div>
    </router-link>

    <div
      class="bg-[#202126] flex flex-col items-center justify-center gap-4 h-48 px-4"
    >
      <p class="text-xl">合作厂商</p>
      <img class="w-full" src="@/assets/images/friend.png" alt="images" />
    </div>
    <p class="bg-[#111111] text-sm text-center py-4">
      © New Pu Jung All Rights Reserved
    </p>
  </div>
  <MainMenu :isShow="isMenuShow" @close="isMenuShow = false" />
  <van-popup
    v-model:show="loginShow"
    round
    :overlay-style="{ padding: '1rem' }"
    :style="{ width: '80%', height: '150px', padding: '1rem' }"
  >
    <div
      class="flex items-center justify-around gap-3 text-black flex-col w-full h-full font-medium"
    >
      请先登入
      <router-link
        to="/sign"
        class="bg-[#24B6E4] text-white w-32 py-2 rounded-full text-center"
        >OK</router-link
      >
    </div>
  </van-popup>
</template>

<script setup>
import { useStore } from 'vuex';
import { computed, onMounted, ref } from 'vue';
import Marquee from '../components/Home/Marquee.vue';
import Counter from '../components/Home/Counter.vue';
import FlipClock from '../components/Home/FlipClock.vue';
import MainMenu from '../components/MainMenu.vue';
import { get_user_info } from '@/api/main';
const store = useStore();
const isMenuShow = ref(false);
const loginShow = ref(false);
const mainInfo = computed(() => store.state.mainInfo);
const isLogin = computed(() => store.state.isLogin);
onMounted(() => {
  get_user_info();
});

const isReadCount = computed(() => {
  return store.state.unread_count?.sys_unread;
});

const authMenuLogin = (action) => {
  if (!isLogin.value) {
    loginShow.value = true;
    return;
  } else {
    isMenuShow.value = true;
  }
};
</script>
<style scoped>
.line {
  background: linear-gradient(180deg, #ff2a6d, #ff2aaf);
}

.btn-pink {
  background: linear-gradient(90deg, #ff2a6d, #ff2aaf);
  box-shadow: 0 0 15px rgba(255, 42, 175, 0.8);
}

.btn-blue {
  background: linear-gradient(90deg, #1da3cd, #25bae9);
  box-shadow: 0 0 15px rgba(21, 186, 236, 0.9);
}

.btn-green {
  background: linear-gradient(90deg, #0e980e, #30b91e);
  box-shadow: 0 0 15px rgba(51, 202, 31, 0.8);
}

.text-shadow {
  text-shadow: rgba(0, 0, 0, 0.3) 0 2px 3px;
}
</style>
