<template>
  <van-popup
    v-model:show="show"
    round
    :overlay-style="{ padding: '1rem' }"
    @close="emits('close')"
    :style="{
      padding: '1rem 0',
      backgroundColor: '#2B2633',
      width: '100%',
      height: '95%',
    }"
  >
    <div class="w-full h-full overflow-y-scroll">
      <div
        class="flex items-center justify-between px-3 pb-4 border-b border-white border-opacity-10"
      >
        <div class="flex items-center gap-3">
          <div class="w-[2px] h-5 line" />
          <p class="text-lg">充值现金</p>
        </div>
        <van-icon @click="close" name="cross" size="24px" color="#909093" />
      </div>
      <div
        class="border-b border-white border-opacity-10 py-8 px-4 text-[15px]"
      >
        <span class="text-[#909093]">电子钱包： </span>${{
          +userInfo.last_point || '0.00'
        }}
      </div>
      <div class="px-3 py-4 text-[14px]">
        <p class="text-lg mb-2">
          <span class="text-[#24B6E4]">步骤1</span> 选择储值方式
        </p>
        <p class="text-[#24B6E4] text-xs mb-2">
          網銀或是手機銀行渠道支付請聯繫線上客服
        </p>
        <p class="text-[#909093] mb-2">储值方式</p>
        <Select
          mode="dark"
          placeholder="请选择储值方式"
          :modelVal="chargeType"
          :options="selectOptions"
          @update:value="chargeType = $event"
        />
        <p class="text-lg mb-2 mt-5">
          <span class="text-[#24B6E4]">步骤2</span> 选择储值目标
        </p>
        <p class="text-[#909093] mb-2">储值目标</p>
        <Select
          mode="dark"
          placeholder="请选择储值方式"
          :modelVal="chargeTarget"
          :options="selectOptions"
          @update:value="chargeTarget = $event"
        />
        <p class="text-lg mb-2 mt-5">
          <span class="text-[#24B6E4]">步骤3</span> 输入金额
        </p>
        <p class="text-[#909093] mb-2">金额</p>
        <input
          class="bg-[#1C1316] w-full flex px-3 items-center justify-between py-3 text-sm mb-4 rounded-lg placeholder-[#909093] border border-transparent hover:border-[#24B6E4]"
          type="number"
        />
      </div>
      <div
        class="mx-3 rounded-full bg-[#24B6E4] text-center py-3 text-sm"
        @click="showFailToast('请联系客服')"
      >
        送出
      </div>
      <p class="text-sm text-[#909093] mt-2 text-center" @click="close">取消</p>
      <div
        v-if="mainInfo.notice"
        class="mt-6 border-t border-[#909093] border-opacity-30 mx-3 pt-4 text-sm"
      >
        <p>注意事项：</p>
        <div
          class="list-decimal list-inside text-[#909093]"
          v-html="mainInfo.notice"
        ></div>
      </div>
    </div>
  </van-popup>
</template>
<script setup>
import { ref, computed, watch } from 'vue';
import { useStore } from 'vuex';
import Select from '../components/Select.vue';
import { showFailToast } from 'vant';

const props = defineProps({
  isShow: {
    type: Boolean,
    default: false,
  },
});
const emits = defineEmits(['close']);
const store = useStore();
const show = ref(false);
const userInfo = computed(() => store.state.userInfo);
const mainInfo = computed(() => store.state.mainInfo);
const selectOptions = ref(['电子钱包']);
const chargeTarget = ref(null);
const chargeType = ref(null);
const close = () => {
  show.value = false;
  emits('close');
};
const hideNumber = (str) => {
  if (!str) return '';
  else return str.substr(0, 3) + '****' + str.substr(-4);
};
watch(
  () => props.isShow,
  (newVal) => {
    show.value = props.isShow;
  }
);
</script>
<style lang="scss" scoped>
.line {
  background: linear-gradient(180deg, #ff2a6d, #ff2aaf);
}
</style>
