/* * 翻牌时钟 * @author： 兔子先生 * @createDate: 2019-11-24 */
<template>
  <div>
    <vue-number-roll-plus :number="nowNum" background="#333">
    </vue-number-roll-plus>
  </div>
</template>

<script setup>
import { get_online_count } from '@/api/main';
import { onMounted, ref } from 'vue';
import VueNumberRollPlus from 'vue3-number-roll-plus';
import 'vue3-number-roll-plus/main.css';
let timer = null;
const lastNum = ref('000');
const nowNum = ref('000');
const maxCount = ref(8);
const minCount = ref(1);
const getOnlineCount = async () => {
  const res = await get_online_count();
  if (res) {
    maxCount.value = String(res.online_count_max).length;
    minCount.value = String(res.online_count_min).length;
    if (lastNum.value === '000') {
      lastNum.value = String(res.online_count).padStart(maxCount.value, '0');
    }
    if (
      nowNum.value !== res.online_count &&
      lastNum.value !== String(res.online_count).padStart(maxCount.value, '0')
    ) {
      lastNum.value = nowNum.value;
    }
    nowNum.value = String(res.online_count).padStart(maxCount.value, '0');
  }
};
const init = async () => {
  await getOnlineCount();
};
const run = () => {
  timer = setInterval(async () => {
    await getOnlineCount();
    let nowTimeStr = String(lastNum.value).padStart(maxCount.value, '0');
    let nextTimeStr = String(nowNum.value).padStart(maxCount.value, '0');
    lastNum.value = nextTimeStr;
  }, 1000 * 10);
};
onMounted(() => {
  init();
  run();
});
// export default {
//   name: 'Counter',
//   data() {
//     return {
//       timer: null,
//       flipObjs: [],
//       lastNum: '000',
//       nowNum: '000',
//       maxCount: 8,
//     };
//   },
//   methods: {
//     // 初始化数字

//     async getOnlineCount() {
//       const res = await get_online_count();
//       if (res) {
//         this.maxCount = String(res.online_count_max).length;
//         this.minCount = String(res.online_count_min).length;
//         this.flipObjs = this.flipObjs.splice(0, this.maxCount);
//         this.max = res.online_count_max;
//         this.min = res.online_count_min;
//         if (this.lastNum === '000') {
//           this.lastNum = String(res.online_count).padStart(this.maxCount, '0');
//         }
//         if (
//           this.nowNum !== res.online_count &&
//           this.lastNum !== String(res.online_count).padStart(this.maxCount, '0')
//         ) {
//           this.lastNum = this.nowNum;
//         }
//         this.nowNum = String(res.online_count).padStart(this.maxCount, '0');
//       }
//     },
//     async init() {
//       await this.getOnlineCount();
//     },
//     // 开始计时
//     run() {
//       this.timer = setInterval(async () => {
//         const max = this.$store.state.mainInfo.online_count_max;
//         const min = this.$store.state.mainInfo.online_count_min;
//         await this.getOnlineCount();
//         let nowTimeStr = String(this.lastNum).padStart(this.maxCount, '0');
//         let nextTimeStr = String(this.nowNum).padStart(this.maxCount, '0');
//         for (let i = 0; i < this.flipObjs.length; i++) {
//           if (nowTimeStr[i] === nextTimeStr[i]) {
//             continue;
//           }
//         }
//         this.lastNum = nextTimeStr;
//       }, 1000 * 10);
//     },
//   },
//   async mounted() {
//     this.init();
//     this.run();
//   },
// };
</script>

<style>
.real-time-num {
  border-radius: 3px;
  text-shadow: rgb(0, 255, 242) 0 0 3px;
  color: #fff;
  font-size: 16px;
  transform: scaleY(0.8);
  margin-top: 3px;
}
.real-time-num div {
  transform: scaleY(1.2);
}
</style>
